var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"book_content_container"},[_vm._m(0),(_vm.loading)?_c('section',{staticClass:"book_content_body"},_vm._l((3),function(n){return _c('BookLevelCardSkeleton',{key:n})}),1):_vm._e(),(!_vm.loading && _vm.pricesLevels.length > 0)?_c('b-tabs',{staticClass:"block mb-0",attrs:{"position":"is-right","is-nav-hidden":_vm.isOnlyOneType},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.pricesLevels),function(price){return _c('b-tab-item',{key:price.id,attrs:{"label":price.label}},[_c('section',{staticClass:"book_content_body"},_vm._l((price.prices),function(level){return _c('BookLevelCard',{key:level.id,attrs:{"price":price,"level":level,"selectedLevel":_vm.selectedLevel,"selectedPrice":_vm.selectedPrice,"isDisabledToUpdate":_vm.isDisabledToUpdate ||
            _vm.disableQuoteByStatus ||
            _vm.hideElementByCondition},on:{"selectLevel":_vm.selectLevel}})}),1)])}),1):_vm._e(),(!_vm.loading && _vm.pricesLevels.length === 0)?_c('p',{staticClass:"book_content_placeholder"},[_vm._v(" There is no price available for this itinerary, please add services to your quote ")]):_vm._e(),_c('BaseModal',{attrs:{"isComponentModalActive":_vm.showStatusChangeModal}},[_c('IconWarning',{staticClass:"icon is-xsmall color-12",attrs:{"slot":"icon"},slot:"icon"}),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("Are you sure to "),_c('span',{staticClass:"has-text-weight-semibold"},[_vm._v("change")]),_vm._v(" this status? ")]),_c('div',{staticClass:"is-flex",attrs:{"slot":"buttons"},slot:"buttons"},[_c('BaseButton',{staticClass:"mr-4",attrs:{"action":"secondary","size":"medium"},on:{"click":_vm.closeModal}},[_c('p',{attrs:{"slot":"text"},slot:"text"},[_vm._v("No, take me back")])]),_c('BaseButton',{attrs:{"action":"tercery","size":"medium","disabled":_vm.loading},on:{"click":function () {
            this$1.closeModal();
            this$1.validateItineraryIsPublished(
              this$1.constants.ITINERARY_STATUS.CONFIRM.value
            );
          }}},[_c('p',{attrs:{"slot":"text"},slot:"text"},[_vm._v("Yes, continue")])])],1)],1),_c('BaseModal',{attrs:{"isComponentModalActive":_vm.showIsItineraryPublishedModal}},[_c('IconWarning',{staticClass:"icon is-xsmall color-12",attrs:{"slot":"icon"},slot:"icon"}),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" This itinerary has not been published yet. Then you will not be able to edit the export. "),_c('br'),_vm._v("Are you sure to "),_c('span',{staticClass:"has-text-weight-semibold"},[_vm._v("change")]),_vm._v(" this status? ")]),_c('div',{staticClass:"is-flex",attrs:{"slot":"buttons"},slot:"buttons"},[_c('BaseButton',{staticClass:"mr-4",attrs:{"action":"secondary","size":"medium","disabled":_vm.loading},on:{"click":function () {
            _vm.showStatusChangeModal = false;
            _vm.showIsItineraryPublishedModal = false;
            _vm.redirectToExportSection();
          }}},[_c('p',{attrs:{"slot":"text"},slot:"text"},[_vm._v("No, redirect to export")])]),_c('BaseButton',{attrs:{"action":"tercery","size":"medium","disabled":_vm.loading},on:{"click":function () { return _vm.handleChangeStatus(_vm.constants.ITINERARY_STATUS.CONFIRM.value); }}},[_c('p',{attrs:{"slot":"text"},slot:"text"},[_vm._v("Yes, continue")])])],1)],1),_c('ExternalExportModal',{attrs:{"isModalOpen":_vm.showExternalExportModal,"loading":_vm.loading,"onExternalExportContinue":_vm.onExternalExportContinue,"closeModal":_vm.closeChangeStatusModal}}),_c('CongratulationsModal',{attrs:{"isModalOpen":_vm.showCongratulationsMessage,"closeModal":function () { return (_vm.showCongratulationsMessage = false); }}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"book_content_header"},[_c('div',{staticClass:"book_content_text"},[_c('h1',[_vm._v("Confirming quote")]),_c('p',[_vm._v(" Please, select a level and then confirm your choice in the opening mode ")])])])}]

export { render, staticRenderFns }